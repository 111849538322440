import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { ClassModel } from "@/models";
import { Teacher } from "@/types/ModelType";
import { Common } from "../Common";

export class ClassForm {
    cid = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) sid = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(20, { message: "最多輸入20字" }) name = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) active = 1;

    tid = "";
}

type response = { [key: string]: unknown };

@Component<AdminClassAddController>({
    components: {
        SideNav, Lightbox
    }
})
export default class AdminClassAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private classForm = new ClassForm();
    private cityList: object = {};
    private schoolList: { [key: string]: any } = {};
    private teacherList: object = {};
    private selectTeacherList: Teacher[] = [];
    private identity = window.localStorage.getItem('admin_identity') as string;
    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;
    private errNo = 0;

    private errorMsgMap: { [key: string]: string } = {
        sid: '',
        name: '',
        active: '',
    };

    public async created() {
        if (this.identity == "1") {
            this.cityList = await Common.getSchoolCity(); //取得有分校之縣市
        } else {
            const sid = window.localStorage.getItem('admin_sid') as string;
            this.cityList = await Common.getCity(sid); //取得該分校之縣市
            for (const cont of this.cityList as any) {
                this.classForm.cid = cont.cid;
            }

            const school = window.localStorage.getItem('admin_school') as string;
            this.schoolList[0] = {
                sid: sid,
                name: school,
            };
            this.classForm.sid = sid;
            this.getTeacher(sid);
        }
    }

    private async getSchool(cid: string) {
        if (this.identity == "1") {
            this.classForm.sid = '';
            this.teacherList = {};
            this.classForm.tid = '';
            this.schoolList = await Common.getSchool(cid);
        }
    }

    private async getTeacher(sid: string) {
        this.classForm.tid = '';
        const item: { [key: string]: string } = {
            sid: sid,
            token: this.token,
        }
        const data = await ClassModel.getTeacher(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.teacherList = data.list as object;
    }

    //增加班級老師
    private addTeacher() {
        const [tid, name, phone] = this.classForm.tid.split(",");
        if (tid != "") {
            //需判斷重複新增
            const item = {
                tid: tid,
                name: name,
                phone: phone,
            }
            this.selectTeacherList.push(item);
            this.classForm.tid = '';
        } else {
            this.errorMsg = "請先選擇老師";
            this.openLB = true;
        }
    }

    //刪除班級老師
    private delTeacher(key: number) {
        if (!this.doubleClick) {
            this.doubleClick = true;

            this.selectTeacherList.splice(key, 1);
            this.doubleClick = false;
        }
    }

    private validateAndSubmit() {
        validate(this.classForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        sid: '',
                        name: '',
                        active: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const item: { [key: string]: unknown } = {
                        sid: this.classForm.sid,
                        name: this.classForm.name,
                        teacherList: this.selectTeacherList,
                        active: this.classForm.active,
                        token: this.token,
                    }
                    this.errNo = await ClassModel.add(item);
                    Common.checkToken(this.errNo);
                    this.openLB = true;
                    this.doubleClick = false;
                    this.errorMsg = ErrorMessage[this.errNo];
                }
            }
        )
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.push("/admin/class");
        }
    }
}
